import React, { FC }        from 'react';
import { 
    useLocation
}                           from 'react-router-dom';
import {
    Credentials
}                           from "@aws-sdk/client-sts";
import { 
    S3Client,
    GetObjectCommand
}                           from "@aws-sdk/client-s3";

import * as UserType        from 'shared/types/User';
import * as VehicleType     from 'shared/types/Vehicle';
import getApiPromise        from 'utils/getApiPromise';

import * as MenuItem        from 'components/MenuItem';
import tectransit           from 'utils/TecTransit';

interface Props {
}
const Photo : FC<Props> = props => {

    const locationState     = useLocation().state as {
        vehicle : VehicleType.Vehicle,
        dvi     : {
            drivers     : UserType.User[];
            selected?   : boolean;
        }
        key     : string;
    };
    const [errMessage,setErrMessage]    = React.useState<string>('');
    const [photoObject,setPhotoObject]  = React.useState<Blob|undefined>();

    React.useEffect(() => {
        if( !tectransit.agency.issue428_s3_bucket_name ) {
            setErrMessage(`S3 bucket not configured`);
            return;
        } 
        getApiPromise<Credentials>('/api/maintenance/dviCredentials','GET')
            .then( credentials => {
                if( !credentials || credentials.err )
                    throw Error(credentials?.err||`credentials are empty`);
                const s3Client = new S3Client({
                    region     : 'us-east-2',
                    credentials: {
                        accessKeyId     : credentials.AccessKeyId!,
                        secretAccessKey : credentials.SecretAccessKey!,
                        sessionToken    : credentials.SessionToken
                    }
                });
                s3Client.send(new GetObjectCommand({
                    Bucket  : tectransit.agency.issue428_s3_bucket_name,
                    Key     : locationState.key
                })).then( getObjectOutput => {
                    if( !getObjectOutput.Body )
                        throw Error(`no body in getObjectOutput`);
                    return getObjectOutput.Body.transformToWebStream();
                }).then( str => {
                    return new Response(str).blob();
                }).then( blob => {
                    setPhotoObject(blob);
                })
            })
            .catch( err => {
                setErrMessage(`Cannot get DVIs (${err.message})`);
            });
    },[locationState.key]);

    return MenuItem.withMenuItem(`Photo of DVI record for Vehicle #${locationState.vehicle._id}`, (alert) => {
        if( errMessage ) {
            alert.set(errMessage);
            return false;
        }
        if( !photoObject )
            return (<div>Loading...</div>);
        return (
            <img 
                alt="user dvi" 
                src={URL.createObjectURL(photoObject)}
                style={{display:'block',margin:'auto',width:'50%'}}
            />
        );
    });
}

export default Photo;
