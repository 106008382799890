import React             from 'react';
import { 
    NavigateFunction,
    useNavigate
}                        from 'react-router-dom';

import consts            from 'shared/consts';
import * as VehicleType  from 'shared/types/Vehicle';

import MenuItem          from 'components/MenuItem';
import InfiniteTable     from 'components/InfiniteTable';

interface Props {
    navigate: NavigateFunction
}
class VehiclesTable extends InfiniteTable<VehicleType.Vehicle & {unrepaired_dvis_count?:number}> {
    public props : Props;
    constructor( props:Props ) {
        super({});
        this.props = props;
    }
    fetchRows( orderby:string, limit:number, skip:number ) {
        return this.getTablePromise('/api/maintenance/vehicles','GET',undefined,{orderby,limit,skip});
    }
    renderThead() {
        return (
            <tr>
                <th onClick={()=>this.sort('_id')}>{this.getSortingIcon('_id')}#</th>
                <th onClick={()=>this.sort('odometer')}>{this.getSortingIcon('odometer')}Odometer</th>
                <th onClick={()=>this.sort('license_plate')}>{this.getSortingIcon('license_plate')}License Plate</th>
                <th onClick={()=>this.sort('make_model_year')}>{this.getSortingIcon('make_model_year')}Make, Model, Year</th>
                <th onClick={()=>this.sort('unavailability_reason')}>{this.getSortingIcon('unavailability_reason')}Problem</th>
                <th></th>
            </tr>
        );
    }
    renderRow( v:VehicleType.Vehicle & {unrepaired_dvis_count?:number} ) {
        return (
            <tr key={v._id}>
                <td>{v._id}</td>
                <td>{Number(v.odometer_meters/consts.meters_in_mile).toFixed(2)}</td>
                <td>{`${v.license_plate} ${v.license_state||''}`}</td>
                <td>{v.make_model_year}</td>
                <td>{v.unavailability_reason||((v.unrepaired_dvis_count||0)>0?`${v.unrepaired_dvis_count||0} problem(s)`:``)}</td>
                <td>
                    <button
                        className="btn-theme btn-small" 
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.navigate(`/Maintenance/DVIs`,{
                                state : {vehicle:v}
                            });
                        }}
                    >
                        View DVIs
                    </button>
                </td>
            </tr>
        );
    }
}

export default function Vehicles() {
    const navigate = useNavigate();
    return (
        <MenuItem title="Vehicles">
            <VehiclesTable 
                navigate={navigate}
            />
        </MenuItem>
    );
}
